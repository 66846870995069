export const AUCTION_TYPE_STANDARD = "STANDARD";
export const AUCTION_TYPE_SILENT = "SILENT";
export const AUCTION_TYPES = [AUCTION_TYPE_STANDARD /*, AUCTION_TYPE_SILENT*/];

export const OBJECT_TYPE_PROPERTY = "PROPERTY";
export const OBJECT_TYPE_CAR = "CAR";
export const OBJECT_TYPES = [OBJECT_TYPE_PROPERTY /*, OBJECT_TYPE_CAR*/];

export const PAYMENT_SOURCE_BANK_DRAFT = "BANK_DRAFT";

export const PAYMENT_SOURCE_CERTIFIED_CHECK = "CERTIFIED_CHECK";

export const PAYMENT_SOURCE_POST_ORDER = "POST_ORDER";

export const PAYMENT_SOURCE_CASH = "CASH";


export const PAYMENT_SOURCE_NORMAL_CHECK = 'NORMAL_CHECK';

export const PAYMENT_SOURCE_FIDEOCOMMIS_CHECK = 'FIDEOCOMMIS_CHECK';

export const PAYMENT_SOURCE_BANK_TRANSFER = 'BANK_TRANSFER';

export const AUCTION_PAYMENTS = [
  PAYMENT_SOURCE_CASH,
  PAYMENT_SOURCE_CERTIFIED_CHECK,
  PAYMENT_SOURCE_FIDEOCOMMIS_CHECK,
  PAYMENT_SOURCE_NORMAL_CHECK,
  PAYMENT_SOURCE_POST_ORDER,
  PAYMENT_SOURCE_BANK_DRAFT,
  PAYMENT_SOURCE_BANK_TRANSFER,
];
